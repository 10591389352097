import React from 'react';
import loadable from '@loadable/component';

import * as S from './styles';

const Container = loadable(() => import('../../common/Container'));
const ContactForm = loadable(() => import('../../components/ContactForm'));

const Contact = () => {
  return (
    <Container>
      <S.PageContainer>
        <ContactForm />
      </S.PageContainer>
    </Container>
  );
};

export default Contact;