import React from 'react';
import loadable from '@loadable/component';

import ContactBlock from '../../content/contactBlock.json';

const ContactFrom = loadable(() => import('../../components/ContactForm'));
const IntroContentBlock = loadable(() => import('../../components/IntroContentBlock'));
const AboutContentBlock = loadable(() => import('../../components/AboutContentBlock'));
const InstagramLinkBlock = loadable(() => import('../../components/InstagramLinkBlock'));
const YoutubeVideoBlock = loadable(() => import('../../components/YoutubeVideoBlock'));
const GalleryBlock = loadable(() => import('../../components/GalleryBlock'));
const Container = loadable(() => import('../../common/Container'));

const Home = () => {
  return (
    <Container>
      <IntroContentBlock first="true"/>
      <AboutContentBlock />
      <GalleryBlock />
      <InstagramLinkBlock />
      <YoutubeVideoBlock />
      <ContactFrom title={ContactBlock.title} content={ContactBlock} />
    </Container>
  );
};

export default Home;
