import React from 'react';
import loadable from '@loadable/component';

const AboutContentBlock2 = loadable(() => import('../../components/AboutContentBlock2'));
const AboutPDRBlock = loadable(() => import('../../components/AboutPDRBlock'));
const BenefitsBlock = loadable(() => import('../../components/BenefitsBlock'));
const Container = loadable(() => import('../../common/Container'));

const About = () => {
  return (
    <Container>
      <AboutPDRBlock />
      <BenefitsBlock />
      <AboutContentBlock2 />
    </Container>
  );
};

export default About;