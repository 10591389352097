import styled from "styled-components";
import { Drawer } from 'antd';
import { MenuOutlined } from "@ant-design/icons";
import loadable from '@loadable/component';

import theme from '../../config/theme.json';

const ImageIcon = loadable(() => import('../../common/ImageIcon'));


export const Header = styled.header`
  padding: 1rem 0.5rem;
  position: fixed;
  height: 100px;
  padding-top: 50px;
  // background-color: ${theme.primaryAccentColor};
  background-color: black;
  z-index: 999;
  width: 100%;
  top: 0;

  @media only screen and (max-width: 411px) {
    padding-top: 25px;
    height: 75px;
  }
`;

export const LogoContainer = styled.a`
  display: flex;
  align-items: flex-start;
  position: absolute;
  background-color: black;
  padding: 25px 10px;
  left: 25px;
  top: -70px;

  @media only screen and (max-width: 411px) {
    padding: 20px 10px;
    top: -40px;
  }
`;

export const LogoImage = styled(ImageIcon)`
  @media only screen and (max-width: 411px) {
    width: 75px;
    height: auto;
  }
`;


export const DrawerStyle = styled(Drawer)`
`;


export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1580px;
  padding-right: 25px;
  padding-left: 25px;
  margin-right: auto;
  margin-left: auto;
`;

export const NavLink = styled.div`
  display: inline-block;
  text-align: center;
`;

export const CustomNavLink = styled.div`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled.div`
  cursor: pointer;
  width: ${(props) => (props.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled.div`
  @media only screen and (max-width: 768px) {
    display: block !important;
  }
  display: none;
`;

export const NotHidden = styled.div`
  display: flex;
  justify-content: center;
  height: 20px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.h5`
  font-size: 1.37rem;
  margin-top: -0.45rem;
  color: black;
  padding: 0 1.56rem 0 0;
  font-weight: 600;
  border-bottom: 5px solid #111B47;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1rem;
  color: white;
  transition: color 0.2s ease-in;
  margin: 0rem 2rem;
  // border: 1px solid white;

  a {
    color: white;
  }

  a:visited {
    color: white;
  }

  a:hover {
    color: ${theme.primaryAccentColor};
  }

  @media only screen and (max-width: 768px) {
    a {
      color: black;
    }
  
    a:visited {
      color: black;
    }

    a:hover {
      color: ${theme.primaryAccentColor};
    }
  }


  @media only screen and (max-width: 768px) {
    margin: 1.25rem 2rem;
  }
  
  div {
    cursor: pointer;
    color: white;
    fill: currentColor;

    &:hover {
      fill: ${theme.primaryAccentColor};
    }
  }
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
  padding-right: ${(props) => (props.padding ? "10px" : "")};
`;
