import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import { Fade } from 'react-reveal';
import loadable from '@loadable/component';


import * as S from './styles';

const SocialLink = loadable(() => import('../../common/SocialLink'));

const Footer = () => {
  return (
    <Fragment>
      <Fade bottom>
        <S.Footer>
            <Row
              type="flex"
              justify="space-between"
              style={{width: '100%'}}
            >
              <Col lg={12} sm={24}>
                <S.FooterText>&#169; 2021 Prime Paintless Dent Removal</S.FooterText>
              </Col>

              <Col lg={12} sm={24} style={{width: '100%'}}>
                <S.SocialIconContainer>

                  <S.NavLink>
                      <SocialLink
                        href="https://www.instagram.com/primepaintlessdentremoval/"
                        src="instagram.svg"
                      />
                    </S.NavLink>

                  <S.NavLink>
                    <SocialLink
                      href="https://www.facebook.com/PrimePaintlessDentRemoval/"
                      src="facebook.svg"
                    />
                  </S.NavLink>

                </S.SocialIconContainer>
              </Col>
            </Row>
        </S.Footer>
      </Fade>
    </Fragment>
  );
};

export default Footer;
