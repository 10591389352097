import { createGlobalStyle } from 'styled-components';
import theme from './config/theme.json';

const Styles = createGlobalStyle`

    body,
    html,
    a {
        font-family: ${theme.primaryFontFamily};
    }

    body {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        overflow-x: hidden;
    }

    a:hover {
        color: #00A2E9;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: ${theme.headerFontFamily};
        
        color: white;
        font-size: 2.575rem;
        line-height: 3.0625rem;
      
        @media only screen and (max-width: 414px) {
          font-size: 1.625rem;
        }
    }

    p {
        color: white;
        font-size: 1.125rem;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: white;

        :hover {
            color: #00A2E9;
        }

        :visited {
            color: white;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        padding: 1.25rem;
        text-align: left;
        padding-top: 2.5rem;
        padding-right: 2rem;
    }

    .anticon,
    .ant-notification-notice-icon-success {
        color: ${theme.primaryAccentColor};
    }
`;

export default Styles;
