import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../../config/theme.json';


export const NavLink = styled(Link)`
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }

  div {
    cursor: pointer;
    color: white;
    fill: currentColor;
    padding: 0 5px;

    &:hover {
      fill: ${theme.primaryAccentColor};
    }
  }
`;

export const Footer = styled.section`
  background-color: black;
  width: 100%;
  display: flex;
  height: 100px;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;

`;


export const FooterText = styled.div`
  font-size: 1rem;
  color: white;

  @media only screen and (max-width: 480px) {
    font-size: .9rem;
  }

`;

export const SocialIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    @media only screen and (max-width: 480px) {
      padding-top: 10px;
      justify-content: center;
    }

`;
