import React from 'react';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';

import { 
  BrowserRouter,
  Route 
} from "react-router-dom";

import GlobalStyles from '../globalStyles';

const Router = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
        <GlobalStyles />
        <Header />
        <Route path="/" component={Home} exact={true} />
        <Route path="/about" component={About} exact={true}></Route>
        <Route path="/contact" component={Contact} exact={true}></Route>
        <Footer />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default Router;
